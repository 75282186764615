import { createStore } from 'vuex';
import axios from 'axios';

import alert from './store/modules/alert.js'; // Importe o módulo de alertas

import breadcrumb from './store/modules/breadcrumb.js'; // Importe o módulo de breadcrumb
 import alertaDeConfirmacao from './store/modules/alertaDeConfirmacao.js'; // Importe o módulo de alerta de confirmação
import resourceLimits from './store/modules/resourceLimits.js';
export default createStore({
  state: {
    user: {},
    config: window.config,  // Adicione o objeto config ao state
    token: localStorage.getItem('token_chat_inteligente'),
    tipo_usuario_chat_inteligente: localStorage.getItem('tipo_usuario_chat_inteligente')
    
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setTipoUsuario(state, tipo_usuario_chat_inteligente) {
      state.user.tipo_usuario_chat_inteligente = tipo_usuario_chat_inteligente;
    },
    setAtendente(state, atendente) {
      console.log('--------------------- atendente', atendente);
      state.atendente = atendente;
    }
  },
  actions: {
    async fetchUser({ commit }) {
      const token = localStorage.getItem('token_chat_inteligente');
      try {
        const response = await axios.post('/api/getUserInfo', { token_chat_inteligente: token });
        if (response.data.success) {
          const user = response.data.dados_usuario;
          commit('setUser', user);
        } else {
          console.error('Erro ao buscar usuário:', response.data.message);
        }
      } catch (error) {
        console.error('Erro ao buscar usuário:', error);
      }
    },
  },
  getters: {
    user: state => state.user,
    token: state => state.token,
    tipo_usuario_chat_inteligente: state => state.tipo_usuario_chat_inteligente,
    flg_usuario_eh_um_atendente: state => state.tipo_usuario_chat_inteligente === 'atendente',
    atendente: state => state.atendente,
    config: state => state.config  // Adicione um getter para o config
    
  },
  modules: {
    alert, // Inclua o módulo de alertas
    breadcrumb, // Inclua o módulo de breadcrumb
    alertaDeConfirmacao,
    resourceLimits
  }
});
