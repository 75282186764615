import axios from 'axios';

const state = {
  resourceLimits: {},
  showUpgradeModal: false,
  resourceTypeExceeded: '',
  nextPlan: null, // Adicionado para armazenar o próximo plano
  currentPlan: null
};

const mutations = {
  SET_RESOURCE_LIMITS(state, payload) {
    state.resourceLimits = payload;
  },
  SHOW_UPGRADE_MODAL(state, { resourceType, nextPlan, currentPlan }) {
    state.showUpgradeModal = true;
    state.resourceTypeExceeded = resourceType;
    state.nextPlan = nextPlan; // Armazena o próximo plano quando o limite é atingido
    state.currentPlan = currentPlan;
  },
  HIDE_UPGRADE_MODAL(state) {
    state.showUpgradeModal = false;
    state.resourceTypeExceeded = '';
    state.nextPlan = null; // Limpa o próximo plano
  }
};

const actions = {
  async fetchResourceLimits({ commit, rootState }, resourceType) {
    try {
      const token = rootState.token;
      const response = await axios.post(
        '/api/canCreateResource',
        { resourceType },
        {
          headers: {
            'Authorization': token
          }
        }
      );
      
      console.log('vaove');
      console.log(response.data);

      if (response.data.success) {
        commit('SET_RESOURCE_LIMITS', response.data);

        if (!response.data.canCreate) {
          commit('SHOW_UPGRADE_MODAL', {
            resourceType,
            nextPlan: response.data.nextPlan, // Armazena o próximo plano retornado
            currentPlan: response.data.currentPlan
          });
        }

        // Retornar o resultado completo da API
        return { success: true, data: response.data };
      } else {
        // Retornar a falha se a API não retornou sucesso
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Error fetching resource limits:', error);

      // Retornar erro no catch
      return { success: false, message: 'Erro ao buscar limites de recurso' };
    }
  },
  hideUpgradeModal({ commit }) {
    commit('HIDE_UPGRADE_MODAL');
  }
};

const getters = {
  showUpgradeModal: state => state.showUpgradeModal,
  resourceTypeExceeded: state => state.resourceTypeExceeded,
  resourceLimits: state => state.resourceLimits,
  nextPlan: state => state.nextPlan, // Getter para retornar o próximo plano
  currentPlan: state => state.currentPlan
};

export default {
  state,
  mutations,
  actions,
  getters
};
